var _a;
import { EventEmitter } from "./helpers/event-emitter";
import { BASE_URL } from "./shared.configs";
import { ScreenBreakpoint } from "./shared.types";
export var SKELETON_THEME_PROPS = {
    borderRadius: "0.5rem",
};
export var SCREENS = (_a = {},
    _a[ScreenBreakpoint.SM] = '640px',
    _a[ScreenBreakpoint.MD] = '768px',
    _a[ScreenBreakpoint.LG] = '1024px',
    _a[ScreenBreakpoint.XL] = '1280px',
    _a[ScreenBreakpoint.XXL] = '1536px',
    _a);
export var USER_INPUT_DEBOUNCE_TIMEOUT = 700;
export var UNTITLED_LABEL = "Untitled";
export var FEATURE_REQUESTS_URL = 'https://feedback.podcastle.ai/feature-requests';
export var PRESS_URL = BASE_URL + "/press";
export var BLOG_URL = BASE_URL + "/blog";
export var SUPPORT_URL = "https://help.podcastle.ai/en";
export var landingNavigationItems = [
    {
        id: 1,
        name: 'Feature requests',
        external: true,
        disabled: false,
        defaultPathIndex: 0,
        paths: [
            {
                path: FEATURE_REQUESTS_URL,
            },
        ],
    },
    {
        id: 2,
        name: 'Help & support',
        external: true,
        disabled: false,
        defaultPathIndex: 0,
        paths: [
            {
                path: SUPPORT_URL,
            },
        ],
    },
    {
        id: 3,
        name: 'Blog',
        external: true,
        disabled: false,
        defaultPathIndex: 0,
        paths: [
            {
                path: BLOG_URL,
            },
        ],
    },
    {
        id: 4,
        name: 'Press',
        external: true,
        disabled: false,
        defaultPathIndex: 0,
        paths: [
            {
                path: PRESS_URL,
            },
        ],
    },
];
export var DURATION_SCALE = 1000;
export var playerCastlingEventEmitter = new EventEmitter();
