var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { fetchProfile } from 'modules/auth/auth.requests';
import { useToken } from 'modules/auth/hooks/use-token';
import { useEffect, useState } from 'react';
import { AuthContext } from '../auth.context';
export var ProtectedRoute = function (_a) {
    var Component = _a.component;
    var _b = __read(useToken(), 1), token = _b[0];
    var _c = __read(useState(), 2), user = _c[0], setUser = _c[1];
    var _d = __read(useState(true), 2), loading = _d[0], setLoading = _d[1];
    useEffect(function () {
        if (token) {
            fetchProfile().then(function (res) {
                if (res) {
                    setUser(res);
                    setLoading(false);
                }
            });
        }
        else {
            setLoading(false);
        }
    }, [token]);
    return (_jsx(AuthContext.Provider, __assign({ value: {
            user: user,
            loading: loading,
        } }, { children: _jsx("div", __assign({ className: 'flex flex-col h-screen max-h-screen min-h-screen' }, { children: _jsx("div", __assign({ className: 'flex-1 overflow-auto' }, { children: _jsx(Component, {}, void 0) }), void 0) }), void 0) }), void 0));
};
