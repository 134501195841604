var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import { classes } from 'react-scoped-styles';
import { BrandLoading } from '../brand-loading/brand-loading';
import './app-loading.css';
export var AppLoading = function (_a) {
    var _b = _a.wholePage, wholePage = _b === void 0 ? false : _b, _c = _a.overlay, overlay = _c === void 0 ? false : _c;
    var domBody = document.querySelector('body');
    var loadingClasses = classes('app-loading', {
        'fixed h-screen w-screen full-screen': wholePage,
        'bg-sys-white bg-opacity-60': overlay,
    });
    var loading = (_jsx("div", __assign({ className: loadingClasses }, { children: _jsx("div", { children: _jsx("div", __assign({ className: 'p-16' }, { children: _jsx("div", __assign({ className: 'm-auto inline-block' }, { children: _jsx(BrandLoading, { width: 64 }, void 0) }), void 0) }), void 0) }, void 0) }), void 0));
    return wholePage ? createPortal(loading, domBody) : loading;
};
