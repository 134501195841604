export var getStringFirstCharacter = function (value) { return value === null || value === void 0 ? void 0 : value.charAt(0).toUpperCase(); };
export var capitalizeFirstLetter = function (value) {
    if (value === void 0) { value = ''; }
    return "" + getStringFirstCharacter(value) + value.toLowerCase().slice(1);
};
export var getUuid = function () { return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    var v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
}); };
export var generateId = function () { return (Math.round(Math.random() * 1e+10)).toString(16); };
