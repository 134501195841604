var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext } from "react";
import { stickyPlayerEventEmitter } from "../player.constants";
import { StickyPlayerEvent } from "../player.types";
import { StickyPlayerContext } from "../player.context";
export var useStickyPlayer = function (file) {
    var _a;
    var _b = useContext(StickyPlayerContext), stickyPlayer = _b.stickyPlayer, setStickyPlayer = _b.setStickyPlayer;
    var openStickyPlayer = function (file, label, playing) {
        if (playing === void 0) { playing = true; }
        if (!file) {
            return;
        }
        setStickyPlayer(__assign(__assign({}, stickyPlayer), { file: file, label: label, playing: playing }));
    };
    var toggleStickyPlayer = function (shouldPlay) {
        setStickyPlayer(__assign(__assign({}, stickyPlayer), { playing: shouldPlay === undefined ? !stickyPlayer.playing : shouldPlay }));
    };
    var playing = stickyPlayer.playing;
    var selectedForPlaying = (file === null || file === void 0 ? void 0 : file.url) === ((_a = stickyPlayer.file) === null || _a === void 0 ? void 0 : _a.url) && !!(file === null || file === void 0 ? void 0 : file.url);
    var currentIsPlaying = playing && selectedForPlaying;
    var forceHide = function () {
        stickyPlayerEventEmitter.emit(StickyPlayerEvent.FORCE_HIDE, null);
    };
    return {
        openStickyPlayer: openStickyPlayer,
        toggleStickyPlayer: toggleStickyPlayer,
        playing: playing,
        selectedForPlaying: selectedForPlaying,
        currentIsPlaying: currentIsPlaying,
        isPlayerFilled: !!stickyPlayer.file,
        forceHide: forceHide,
        stickyPlayer: stickyPlayer,
    };
};
