var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { Progress } from '../progress/progress';
import { DURATION_SCALE } from 'modules/shared/shared.constants';
import { useHover } from 'modules/shared/hooks/use-hover';
import { useMouseMove } from 'modules/shared/hooks/use-mouse-move';
export var DraggableProgress = function (_a) {
    var value = _a.value, _b = _a.duration, duration = _b === void 0 ? 0 : _b, setValue = _a.setValue, _c = _a.deps, deps = _c === void 0 ? [] : _c, alwaysVisibleHandler = _a.alwaysVisibleHandler, props = __rest(_a, ["value", "duration", "setValue", "deps", "alwaysVisibleHandler"]);
    var progressRef = useRef();
    var handleChange = useCallback(function (progress) {
        var value = duration / DURATION_SCALE / 100 * progress;
        setValue(value);
    }, __spreadArray([setValue], __read(deps)));
    useMouseMove(progressRef, handleChange);
    var hovered = useHover(progressRef);
    return (_jsx(Progress, __assign({}, props, { containerRef: progressRef, value: value, handler: true, hovered: alwaysVisibleHandler !== null && alwaysVisibleHandler !== void 0 ? alwaysVisibleHandler : hovered, touchSpace: 8 }), void 0));
};
