import { useEffect, useRef } from 'react';
export var useMouseMove = function (elementRef, cb) {
    var pressedRef = useRef(false);
    var handler = function (event) {
        var clientX = event.clientX;
        var _a = elementRef.current.getBoundingClientRect(), left = _a.left, width = _a.width;
        var delta = clientX - left;
        var percent = delta * 100 / width;
        if (percent >= 0 && percent <= 100) {
            cb(percent);
        }
    };
    var turnOnPressed = function () { return pressedRef.current = true; };
    var turnOffPressed = function () { return pressedRef.current = false; };
    var handleMouseMove = function (event) { return pressedRef.current && handler(event); };
    useEffect(function () {
        var _a, _b;
        (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener("mousedown", turnOnPressed);
        (_b = elementRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener("click", handler);
        document.body.addEventListener("mouseup", turnOffPressed);
        document.body.addEventListener("mousemove", handleMouseMove);
        return function () {
            var _a, _b;
            (_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("mousedown", turnOnPressed);
            (_b = elementRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener("click", handler);
            document.body.removeEventListener("mouseup", turnOffPressed);
            document.body.removeEventListener("mousemove", handleMouseMove);
        };
    }, [elementRef.current, cb]);
};
