var _a;
import { IconButtonSize } from "./icon-button.types";
export var ICON_BUTTON_SIZES = (_a = {},
    _a[IconButtonSize.EXTRA_LARGE] = 48,
    _a[IconButtonSize.LARGE] = 44,
    _a[IconButtonSize.MEDIUM] = 36,
    _a[IconButtonSize.SEMI_MEDIUM] = 32,
    _a[IconButtonSize.SMALL] = 24,
    _a[IconButtonSize.BASE] = 24,
    _a[IconButtonSize.EXTRA_SMALL] = 24,
    _a);
