var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState, } from 'react';
import { classes } from 'react-scoped-styles';
import { Icon } from '../icon/icon';
import { IconButtonSize } from './icon-button.types';
import { ICON_BUTTON_SIZES } from './icon-button.constants';
import './icon-button.css';
import { isTouchDevice } from 'modules/shared/helpers/device';
var DISABLE_TIMEOUT = 0.2 * 1000;
export var IconButton = function (_a) {
    var _b;
    var _c = _a.className, className = _c === void 0 ? '' : _c, icon = _a.icon, _d = _a.size, size = _d === void 0 ? IconButtonSize.SMALL : _d, _e = _a.color, color = _e === void 0 ? '' : _e, bgColor = _a.bgColor, _f = _a.border, border = _f === void 0 ? false : _f, _g = _a.borderColor, borderColor = _g === void 0 ? 'border-variant-2' : _g, _h = _a.shadowed, shadowed = _h === void 0 ? false : _h, _j = _a.shadow, shadow = _j === void 0 ? 'surfaceShadowLow' : _j, buttonRef = _a.buttonRef, _k = _a.hoverColor, hoverColor = _k === void 0 ? 'state-hover' : _k, _l = _a.active, active = _l === void 0 ? false : _l, _m = _a.activeColor, activeColor = _m === void 0 ? color ? color : icon.color : _m, _o = _a.activeBgColor, activeBgColor = _o === void 0 ? 'sys-white' : _o, _p = _a.activeOpacity, activeOpacity = _p === void 0 ? 1 : _p, _q = _a.bgOpacity, bgOpacity = _q === void 0 ? 100 : _q, disabled = _a.disabled, backdropBlur = _a.backdropBlur, cursorInitial = _a.cursorInitial, onClick = _a.onClick, _r = _a.rounded, rounded = _r === void 0 ? 'full' : _r, children = _a.children, props = __rest(_a, ["className", "icon", "size", "color", "bgColor", "border", "borderColor", "shadowed", "shadow", "buttonRef", "hoverColor", "active", "activeColor", "activeBgColor", "activeOpacity", "bgOpacity", "disabled", "backdropBlur", "cursorInitial", "onClick", "rounded", "children"]);
    var _s = __read(useState(!!disabled), 2), iconDisabled = _s[0], setIconDisabled = _s[1];
    var mountedRef = useRef(true);
    var buttonSize = ICON_BUTTON_SIZES[size];
    var classNames = classes('icon-btn', 'w-' + buttonSize, 'h-' + buttonSize, "" + className, (_b = {},
        _b['rounded-' + rounded] = !!rounded,
        _b['shadow-' + shadow] = shadowed,
        _b['bg-' + activeBgColor] = active && !!activeBgColor,
        _b['bg-' + bgColor + ' ' + (!!bgOpacity ? 'bg-opacity-' + bgOpacity : '')] = !!bgColor,
        _b['bg-' + color] = !active && !!color,
        _b['backdrop-blur-' + backdropBlur] = !!backdropBlur,
        _b['bg-opacity-' + activeOpacity] = active && !!activeOpacity,
        _b['border border-' + borderColor] = !!border,
        _b['hover:bg-' + hoverColor + ' ' + (bgOpacity ? 'hover:bg-opacity-' + bgOpacity : '')] = !active && !!hoverColor && !isTouchDevice(),
        _b['disabled'] = iconDisabled,
        _b['active'] = active,
        _b['cursor-auto'] = !!cursorInitial,
        _b));
    var onClickHandler = function (e) {
        if (disabled || typeof onClick !== 'function') {
            return;
        }
        setIconDisabled(true);
        onClick(e);
        setTimeout(function () {
            if (mountedRef.current) {
                setIconDisabled(false);
            }
        }, DISABLE_TIMEOUT);
    };
    useEffect(function () {
        if (disabled !== iconDisabled) {
            setIconDisabled(!!disabled);
        }
    }, [disabled]);
    useEffect(function () {
        return function () {
            mountedRef.current = false;
        };
    }, []);
    var colorValue = useMemo(function () { return active && activeColor ? activeColor : icon.color; }, [active, activeColor, icon.color]);
    return (_jsx("button", __assign({}, props, { onClick: onClickHandler, className: classNames, ref: buttonRef }, { children: children || (_jsx(Icon, __assign({}, icon, { color: colorValue, className: 'pointer-events-none' }), void 0)) }), void 0));
};
