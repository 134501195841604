export var StorageKey;
(function (StorageKey) {
    StorageKey["TOKEN"] = "token";
    StorageKey["USER"] = "user";
})(StorageKey || (StorageKey = {}));
export var SortBy;
(function (SortBy) {
    SortBy["CREATED_AT"] = "createdAt";
    SortBy["UPDATED_AT"] = "updatedAt";
})(SortBy || (SortBy = {}));
export var ViewTemplate;
(function (ViewTemplate) {
    ViewTemplate[ViewTemplate["LIST"] = 0] = "LIST";
    ViewTemplate[ViewTemplate["GRID"] = 1] = "GRID";
})(ViewTemplate || (ViewTemplate = {}));
export var ScreenBreakpoint;
(function (ScreenBreakpoint) {
    ScreenBreakpoint["SM"] = "sm";
    ScreenBreakpoint["MD"] = "md";
    ScreenBreakpoint["LG"] = "lg";
    ScreenBreakpoint["XL"] = "xl";
    ScreenBreakpoint["XXL"] = "2xl";
})(ScreenBreakpoint || (ScreenBreakpoint = {}));
export var LanguageCode;
(function (LanguageCode) {
    LanguageCode["US"] = "US";
    LanguageCode["SPANISH"] = "SPANISH";
    LanguageCode["GERMAN"] = "GERMAN";
    LanguageCode["FRENCH"] = "FRENCH";
    LanguageCode["ITALIAN"] = "ITALIAN";
})(LanguageCode || (LanguageCode = {}));
export var SystemPaletteTheme;
(function (SystemPaletteTheme) {
    SystemPaletteTheme["DARK"] = "dark";
    SystemPaletteTheme["LIGHT"] = "light";
    SystemPaletteTheme["SYSTEM"] = "system";
})(SystemPaletteTheme || (SystemPaletteTheme = {}));
export var Size;
(function (Size) {
    Size["xs"] = "xs";
    Size["sm"] = "sm";
    Size["md"] = "md";
    Size["lg"] = "lg";
    Size["xl"] = "xl";
    Size["2xl"] = "2xl";
})(Size || (Size = {}));
export var MenuAlignment;
(function (MenuAlignment) {
    MenuAlignment["RIGHT"] = "right";
    MenuAlignment["LEFT"] = "left";
    MenuAlignment["CENTER"] = "center";
})(MenuAlignment || (MenuAlignment = {}));
export var UserPaletteTheme;
(function (UserPaletteTheme) {
    UserPaletteTheme["DARK"] = "dark";
    UserPaletteTheme["LIGHT"] = "light";
})(UserPaletteTheme || (UserPaletteTheme = {}));
export var PlayerCastlingEvent;
(function (PlayerCastlingEvent) {
    PlayerCastlingEvent[PlayerCastlingEvent["PLAYED"] = 0] = "PLAYED";
    PlayerCastlingEvent[PlayerCastlingEvent["STOPPED"] = 1] = "STOPPED";
})(PlayerCastlingEvent || (PlayerCastlingEvent = {}));
