export var IconButtonSize;
(function (IconButtonSize) {
    IconButtonSize["EXTRA_LARGE"] = "EXTRA_LARGE";
    IconButtonSize["LARGE"] = "LARGE";
    IconButtonSize["BASE"] = "BASE";
    IconButtonSize["MEDIUM"] = "MEDIUM";
    IconButtonSize["SEMI_MEDIUM"] = "SEMI_MEDIUM";
    IconButtonSize["SMALL"] = "SMALL";
    IconButtonSize["EXTRA_SMALL"] = "EXTRA_SMALL";
})(IconButtonSize || (IconButtonSize = {}));
