var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { clamp } from '../../shared/helpers/number';
import { PlayerCastlingEvent } from 'modules/shared/shared.types';
import { DEFAULT_VOLUME } from '../player.constants';
import { DURATION_SCALE, playerCastlingEventEmitter } from 'modules/shared/shared.constants';
import { useAnimationFrame } from 'modules/shared/hooks/use-animation-frame';
export var usePlayer = function (playablePayload, options) {
    var _a = playablePayload || {}, url = _a.url, _b = _a.duration, duration = _b === void 0 ? 0 : _b;
    var _c = options || {}, _d = _c.defaultVolume, defaultVolume = _d === void 0 ? DEFAULT_VOLUME : _d, _e = _c.preload, preload = _e === void 0 ? false : _e;
    var audioRef = useRef();
    var _f = __read(useState(false), 2), playing = _f[0], setPlaying = _f[1];
    var _g = __read(useState(defaultVolume / 100), 2), volume = _g[0], setVolume = _g[1];
    var _h = __read(useState(false), 2), muted = _h[0], setMuted = _h[1];
    var _j = __read(useState(0), 2), currentTime = _j[0], setCurrentTime = _j[1];
    var _k = __read(useState(1), 2), speed = _k[0], setSpeed = _k[1];
    var _l = __read(useState(false), 2), metaLoaded = _l[0], setMetaLoaded = _l[1];
    useEffect(function () {
        if (!playablePayload) {
            setPlaying(false);
            setCurrentTime(0);
        }
    }, [playablePayload]);
    var togglePlay = function (shouldPlay) {
        if (shouldPlay !== undefined) {
            handlePlaySwitch(shouldPlay);
        }
        else {
            handlePlaySwitch(!playing);
        }
    };
    var onEnd = useCallback(function () {
        handlePlaySwitch(false);
        setCurrentTime(0);
    }, [audioRef.current, url, playablePayload]);
    var onTimeUpdate = useCallback(function () {
        if (metaLoaded && audioRef.current) {
            setCurrentTime(audioRef.current.currentTime);
        }
    }, [audioRef.current, metaLoaded]);
    useAnimationFrame(onTimeUpdate, [playing, metaLoaded]);
    var onMetaLoaded = useCallback(function () {
        setMetaLoaded(true);
    }, []);
    useEffect(function () {
        if (url) {
            if (playing) {
                playerCastlingEventEmitter.emit(PlayerCastlingEvent.PLAYED, url);
            }
            else {
                playerCastlingEventEmitter.emit(PlayerCastlingEvent.STOPPED, url);
            }
        }
    }, [playing, url]);
    useEffect(function () {
        var off = playerCastlingEventEmitter.on(PlayerCastlingEvent.PLAYED, function (playerIdentifier) {
            if (playerIdentifier !== url) {
                togglePlay(false);
            }
        }).off;
        return function () {
            off();
        };
    }, [url]);
    useEffect(function () {
        if (preload) {
            audioRef.current || (audioRef.current = new Audio(url));
            audioRef.current.crossOrigin = "anonymous";
            onMetaLoaded();
        }
        if (playing) {
            audioRef.current || (audioRef.current = new Audio(url));
            audioRef.current.crossOrigin = "anonymous";
            audioRef.current.addEventListener("ended", onEnd);
            audioRef.current.addEventListener("loadedmetadata", onMetaLoaded);
            audioRef.current.play();
        }
        return function () {
            var _a, _b;
            (_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("ended", onEnd);
            (_b = audioRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener("loadedmetadata", onMetaLoaded);
        };
    }, [url, playing, preload, onMetaLoaded]);
    useEffect(function () {
        return function () {
            var _a;
            (_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.pause();
            audioRef.current = undefined;
        };
    }, [url]);
    useEffect(function () {
        if (audioRef.current) {
            audioRef.current.volume = muted ? 0 : volume;
        }
    }, [volume, muted, audioRef.current]);
    useEffect(function () {
        if (audioRef.current) {
            audioRef.current.playbackRate = speed;
        }
    }, [speed, audioRef.current]);
    var handlePlaySwitch = function (play) {
        var _a, _b;
        if (play) {
            (_a = audioRef.current) === null || _a === void 0 ? void 0 : _a.play();
            if (audioRef.current) {
                audioRef.current.currentTime = currentTime;
            }
            setPlaying(true);
        }
        else {
            (_b = audioRef.current) === null || _b === void 0 ? void 0 : _b.pause();
            setPlaying(false);
        }
    };
    var changeVolume = function (volume) { return setVolume(volume / 100); };
    var toggleMuted = function () { return setMuted(!muted); };
    var seek = function (delta) {
        var nextCurrentTime = clamp(audioRef.current.currentTime + delta, 0, audioRef.current.duration);
        audioRef.current.currentTime = nextCurrentTime;
        setCurrentTime(nextCurrentTime);
    };
    var changeCurrentTime = function (currentTime) {
        if (audioRef.current) {
            audioRef.current.currentTime = currentTime;
        }
        setCurrentTime(currentTime);
    };
    var changeSpeed = function (speed) { return setSpeed(speed); };
    var progress = duration ? currentTime * 100 / (duration / DURATION_SCALE) : null;
    return {
        togglePlay: togglePlay,
        playing: playing,
        volume: volume * 100,
        changeVolume: changeVolume,
        muted: muted,
        toggleMuted: toggleMuted,
        seek: seek,
        progress: progress,
        currentTime: currentTime,
        changeCurrentTime: changeCurrentTime,
        speed: speed,
        changeSpeed: changeSpeed,
    };
};
