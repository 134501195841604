import { stringify } from 'query-string';
export var buildUrl = function (path, queryParams) {
    if (!queryParams) {
        return path;
    }
    var params = Object.keys(queryParams).reduce(function (acc, key) {
        if (queryParams[key] !== undefined && queryParams[key] !== null) {
            acc[key] = queryParams[key];
        }
        return acc;
    }, {});
    return path + "?" + stringify(params, { sort: false });
};
