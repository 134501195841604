var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { classes } from 'react-scoped-styles';
import iconDefinitions from 'assets/icons/icon-definitions.svg';
import './icon.css';
export var Icon = function (_a) {
    var _b;
    var name = _a.name, _c = _a.bgColor, bgColor = _c === void 0 ? '' : _c, _d = _a.color, color = _d === void 0 ? 'surface-icon' : _d, _e = _a.size, size = _e === void 0 ? 24 : _e, onClick = _a.onClick, _f = _a.className, className = _f === void 0 ? '' : _f, props = __rest(_a, ["name", "bgColor", "color", "size", "onClick", "className"]);
    var classNames = classes('icon flex-shrink-0', (_b = {},
        _b['w-' + size] = !!size,
        _b['h-' + size] = !!size,
        _b['bg-' + bgColor] = !!bgColor,
        _b['text-' + color] = !!color,
        _b[className] = !!className,
        _b['cursor-pointer'] = !!onClick,
        _b));
    var hrefPrefix = useMemo(function () { return document.getElementById(name) ? '' : iconDefinitions; }, [name]);
    return (_jsx("svg", __assign({}, props, { onClick: onClick, className: classNames }, { children: _jsx("use", { href: hrefPrefix + "#" + name }, void 0) }), void 0));
};
