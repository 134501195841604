var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useRef, useLayoutEffect } from 'react';
export var useAnimationFrame = function (callback, deps) {
    var requestRef = useRef();
    var previousTimeRef = useRef();
    var animate = function (timestamp) {
        if (previousTimeRef.current === undefined) {
            previousTimeRef.current = timestamp;
        }
        var deltaTime = timestamp - previousTimeRef.current;
        callback(deltaTime);
        requestRef.current = requestAnimationFrame(animate);
    };
    useLayoutEffect(function () {
        if (deps.filter(function (dep) { return dep; }).length) {
            requestRef.current = requestAnimationFrame(animate);
            return function () { return cancelAnimationFrame(requestRef.current); };
        }
        else {
            previousTimeRef.current = undefined;
        }
    }, __spreadArray([], __read(deps)));
};
