var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { refreshTokenUrl } from 'modules/shared/shared.configs';
import { HttpError, getHttpStatusByCode, HttpStatusCodes } from '../error-handling/http-error';
import { NetworkError } from '../error-handling/network-error';
import { HttpStatus } from '../services.types';
import { StorageKey } from 'modules/shared/shared.types';
var configureRefreshFetch = function (_a) {
    var refreshToken = _a.refreshToken, fetch = _a.fetch;
    var refreshingTokenPromise = null;
    return function (url, options, skipToken) {
        var token = localStorage.getItem(StorageKey.TOKEN);
        if (refreshingTokenPromise !== null) {
            return (refreshingTokenPromise
                .then(function () { return fetch(url, options, skipToken); })
                .catch(function () { return fetch(url, options, skipToken); }));
        }
        return fetch(url, options, skipToken).catch(function (error) {
            if (error.status === HttpStatus.UNAUTHORIZED && token) {
                if (refreshingTokenPromise === null) {
                    refreshingTokenPromise = new Promise(function (resolve, reject) {
                        refreshToken(JSON.parse(token))
                            .then(function () {
                            refreshingTokenPromise = null;
                            resolve(null);
                        })
                            .catch(function (refreshTokenError) {
                            refreshingTokenPromise = null;
                            reject(refreshTokenError);
                        });
                    });
                }
                return refreshingTokenPromise
                    .catch(function () {
                    throw error;
                })
                    .then(function () { return fetch(url, options, skipToken); });
            }
            else if (error.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                console.log(error);
            }
            else {
                throw error;
            }
        });
    };
};
var getErrorMessageFromResponse = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var contentType, json, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                contentType = response.headers.get('content-type');
                if (!((contentType === null || contentType === void 0 ? void 0 : contentType.indexOf('application/json')) !== -1)) return [3 /*break*/, 2];
                return [4 /*yield*/, response.json()];
            case 1:
                json = _a.sent();
                return [2 /*return*/, json.message || json];
            case 2: return [4 /*yield*/, response.text()];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [3 /*break*/, 6];
            case 5:
                err_1 = _a.sent();
                return [2 /*return*/, undefined];
            case 6: return [2 /*return*/];
        }
    });
}); };
export var refreshToken = function (token) {
    var refreshToken = token.refreshToken;
    return fetchJSONWithToken(refreshTokenUrl, {
        method: 'POST',
        body: JSON.stringify({ refreshToken: refreshToken }),
        headers: { 'Content-type': 'application/json' },
    })
        .then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('TOKEN REFRESHED');
                    return [4 /*yield*/, getContent(response)];
                case 1:
                    data = _a.sent();
                    localStorage.setItem(StorageKey.TOKEN, JSON.stringify(data));
                    return [2 /*return*/, data];
            }
        });
    }); })
        .catch(function (error) {
        console.log('TOKEN REFRESH FAILED');
        localStorage.removeItem(StorageKey.TOKEN);
        throw error;
    });
};
var fetchJSONWithToken = function (url, options, skipToken) {
    var token = localStorage.getItem(StorageKey.TOKEN);
    var optionsWithToken = options;
    if (token && !skipToken) {
        var accessToken = JSON.parse(token).accessToken;
        var headers = __assign({}, optionsWithToken.headers);
        if (accessToken) {
            headers.Authorization = "Bearer " + accessToken;
        }
        optionsWithToken = __assign(__assign({}, optionsWithToken), { headers: headers });
    }
    return fetch(url, optionsWithToken)
        .then(checkStatus);
};
var checkStatus = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if ((response === null || response === void 0 ? void 0 : response.status) >= HttpStatusCodes[HttpStatus.BAD_REQUEST]) {
            return [2 /*return*/, throwHttpError(response)];
        }
        return [2 /*return*/, response];
    });
}); };
export var refreshFetch = configureRefreshFetch({
    refreshToken: refreshToken,
    fetch: fetchJSONWithToken,
});
export var reFetch = function (url, options, skipToken) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, retryParams, fetchOptions;
    return __generator(this, function (_b) {
        _a = options.retryParams, retryParams = _a === void 0 ? { count: 0, timeout: 2000 } : _a, fetchOptions = __rest(options, ["retryParams"]);
        return [2 /*return*/, new Promise(function (resolve, reject) {
                var timeoutId;
                var tryRequest = function (currentRetryCount) {
                    if (currentRetryCount === void 0) { currentRetryCount = 0; }
                    return __awaiter(void 0, void 0, void 0, function () {
                        var response, err_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, refreshFetch(url, fetchOptions, skipToken)];
                                case 1:
                                    response = _a.sent();
                                    clearTimeout(timeoutId);
                                    resolve(response);
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_2 = _a.sent();
                                    if (currentRetryCount >= retryParams.count) {
                                        clearTimeout(timeoutId);
                                        return [2 /*return*/, reject(err_2)];
                                    }
                                    timeoutId = setTimeout(function () {
                                        tryRequest(currentRetryCount + 1);
                                    }, retryParams.timeout);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    });
                };
                tryRequest();
            })];
    });
}); };
var throwHttpError = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var status, message;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                status = getHttpStatusByCode(response.status);
                return [4 /*yield*/, getErrorMessageFromResponse(response)];
            case 1:
                message = (_a.sent()) || status;
                throw new HttpError(status, message);
        }
    });
}); };
export var request = function (url, init, skipToken) { return __awaiter(void 0, void 0, void 0, function () {
    var options;
    return __generator(this, function (_a) {
        options = __assign({}, init);
        return [2 /*return*/, reFetch(url, options, skipToken)
                .catch(function (err) {
                throw new NetworkError(err.message || err.detailMessage);
            })
                .then(checkStatus)];
    });
}); };
var getContent = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var contentType;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if ((response === null || response === void 0 ? void 0 : response.status) === 204) {
                    return [2 /*return*/, ''];
                }
                contentType = response.headers.get('content-type');
                if (!(contentType === null || contentType === void 0 ? void 0 : contentType.includes('application/json'))) return [3 /*break*/, 2];
                return [4 /*yield*/, response.json()];
            case 1: return [2 /*return*/, _a.sent()];
            case 2: return [4 /*yield*/, response.text()];
            case 3: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var fetchJSON = function (url, options) {
    return request(url, __assign({ headers: {
            'Content-Type': 'application/json',
        } }, options)).then(getContent);
};
export var fetchJSONWithAccessToken = function (url, accessToken) {
    var headers = {
        'Content-Type': 'application/json',
    };
    accessToken && (headers.Authorization = "Bearer " + accessToken);
    return request(url, { headers: headers }).then(getContent);
};
export var postJSON = function (url, body, configs, skipToken) {
    if (skipToken === void 0) { skipToken = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, request(url, __assign({ method: 'POST', body: JSON.stringify(body), headers: {
                        'Content-Type': 'application/json',
                    } }, configs), skipToken).then(getContent)];
        });
    });
};
export var putJSON = function (url, body, skipToken) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, request(url, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            }, skipToken).then(getContent)];
    });
}); };
export var patchJSON = function (url, body, skipToken) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, request(url, {
                method: 'PATCH',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            }, skipToken).then(getContent)];
    });
}); };
export var deleteJSON = function (url, body, skipToken) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, request(url, {
                method: 'DELETE',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            }, skipToken).then(getContent)];
    });
}); };
