var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { HttpStatus, IOErrorType } from '../services.types';
import { IOError } from './io-error';
var statusCodeMap = {
    400: HttpStatus.BAD_REQUEST,
    401: HttpStatus.UNAUTHORIZED,
    403: HttpStatus.FORBIDDEN,
    404: HttpStatus.NOT_FOUND,
    409: HttpStatus.CONFLICT,
    500: HttpStatus.INTERNAL_SERVER_ERROR,
    504: HttpStatus.GATEWAY_TIMEOUT,
    308: HttpStatus.PERMANENT_REDIRECT
};
export var HttpStatusCodes = (_a = {},
    _a[HttpStatus.OK] = 200,
    _a[HttpStatus.PERMANENT_REDIRECT] = 308,
    _a[HttpStatus.BAD_REQUEST] = 400,
    _a[HttpStatus.UNAUTHORIZED] = 401,
    _a[HttpStatus.FORBIDDEN] = 403,
    _a[HttpStatus.CONFLICT] = 409,
    _a[HttpStatus.INTERNAL_SERVER_ERROR] = 500,
    _a);
export var ErrorReasons = {
    EXCEED_ONE_TIME_RECORDING: 'EXCEED_ONE_TIME_RECORDING',
    EXCEED_MONTHLY_RECORDING: 'EXCEED_MONTHLY_RECORDING',
    EXCEED_MAX_PARTICIPANTS: 'EXCEED_MAX_PARTICIPANTS',
    REMOVED_PARTICIPANT: 'REMOVED_PARTICIPANT',
    LEAVED_PARTICIPANT: 'LEAVED_PARTICIPANT',
    INTERVIEW_FINISHED: 'INTERVIEW_FINISHED',
    INVALID_ACCESS: 'INVALID_ACCESS',
};
export var getHttpStatusByCode = function (code) { return statusCodeMap[code] || HttpStatus.BAD_REQUEST; };
var HttpError = /** @class */ (function (_super) {
    __extends(HttpError, _super);
    function HttpError(status, message) {
        var _this = _super.call(this, IOErrorType.HTTP_ERROR, message) || this;
        _this.status = status;
        Object.setPrototypeOf(_this, HttpError.prototype);
        return _this;
    }
    return HttpError;
}(IOError));
export { HttpError };
