export var IconName;
(function (IconName) {
    IconName["EXPLICIT"] = "explicit";
    IconName["CLOSE"] = "close";
    IconName["SEARCH"] = "search";
    IconName["ARROW_DOWN"] = "arrow-down";
    IconName["LIST"] = "list";
    IconName["GRID"] = "grid";
    IconName["INFORMATION"] = "information";
    IconName["PAUSE_FILLED"] = "pause-filled";
    IconName["PLAY_FILLED"] = "play-filled";
    IconName["SHARE"] = "share";
    IconName["MORE_VERTICAL"] = "more-vertical";
    IconName["APPLE_PODCASTS"] = "apple-podcasts";
    IconName["SPOTIFY"] = "spotify";
    IconName["GOOGLE_PODCASTS"] = "google-podcasts";
    IconName["FACEBOOK"] = "facebook";
    IconName["FACEBOOK_FILLED"] = "facebook-filled";
    IconName["TWITTER"] = "twitter";
    IconName["TWITTER_FILLED"] = "twitter-filled";
    IconName["LINKEDIN"] = "linkedin";
    IconName["LINKEDIN_FILLED"] = "linkedin-filled";
    IconName["YOUTUBE"] = "youtube";
    IconName["INSTAGRAM"] = "instagram";
    IconName["LOGOUT"] = "logout";
    IconName["USER"] = "user";
    IconName["MY_VOICES"] = "my-voices";
    IconName["BACKWARD_15"] = "backward-15";
    IconName["FORWARD_15"] = "forward-15";
    IconName["SPEED"] = "speed";
    IconName["SPEAKER_MUTE"] = "speaker-mute";
    IconName["SPEAKER_HIGH"] = "speaker-high";
    IconName["CHECK"] = "check";
    IconName["MESSAGE_FILLED"] = "message-filled";
    IconName["ARROW_LEFT"] = "arrow-left";
})(IconName || (IconName = {}));
