import { generateId } from './string';
var EventEmitter = /** @class */ (function () {
    function EventEmitter() {
        this.listeners = [];
    }
    EventEmitter.prototype.on = function (event, listener) {
        var _this = this;
        var subscriptionId = generateId();
        this.listeners.push({
            evt: event,
            cb: listener,
            subscriptionId: subscriptionId
        });
        return {
            subscriptionId: subscriptionId,
            off: function () { return _this.off(subscriptionId); },
        };
    };
    EventEmitter.prototype.off = function (subscriptionId) {
        this.listeners = this.listeners.filter(function (l) { return l.subscriptionId !== subscriptionId; });
    };
    EventEmitter.prototype.emit = function (evt, payload, subscriptionId) {
        if (subscriptionId === void 0) { subscriptionId = ''; }
        this.listeners
            .filter(function (l) { return l.evt === evt && l.subscriptionId !== subscriptionId; })
            .forEach(function (l) { return l.cb(payload); });
    };
    return EventEmitter;
}());
export { EventEmitter };
