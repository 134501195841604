var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useState } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { AppLoading } from 'modules/shared/components/app-loading/app-loading';
import { SKELETON_THEME_PROPS } from 'modules/shared/shared.constants';
import { routes } from 'route/route.config';
import 'tailwindcss/tailwind.css';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ROUTE_BASE_NAME } from 'route/route.constants';
import { ProtectedRoute } from 'modules/auth/protected-route/protected-route';
import { StickyPlayer } from 'modules/player/components/sticky-player/sticky-player';
import { StickyPlayerContext } from 'modules/player/player.context';
import { IconAndSymbolInlineEmbed } from 'modules/shared/components/icon-and-symbol-inline-embed/icon-and-symbol-inline-embed';
// --- Hosting - Public --- //
var EpisodeDetailsPublic = React.lazy(function () { return import('modules/hosting/episode/components/episode-details-public/episode-details-public'); });
var ShowDetailsPublic = React.lazy(function () { return import('modules/hosting/show/components/show-details-public/show-details-public'); });
var EpisodeEmbedPlayer = React.lazy(function () { return import('modules/hosting/episode/components/episode-embed-player/episode-embed-player'); });
var EpisodeDetailsPublicRoute = function (props) { return _jsx(EpisodeDetailsPublic, __assign({}, props), void 0); };
var ShowDetailsPublicRoute = function (props) { return _jsx(ShowDetailsPublic, __assign({}, props), void 0); };
var EpisodeEmbedPlayerRoute = function (props) { return _jsx(EpisodeEmbedPlayer, __assign({}, props), void 0); };
var App = function () {
    var _a = __read(useState({
        file: undefined,
        label: undefined,
        playing: false,
    }), 2), stickyPlayer = _a[0], setStickyPlayer = _a[1];
    return (_jsx(Suspense, __assign({ fallback: _jsx(AppLoading, {}, void 0) }, { children: _jsxs(StickyPlayerContext.Provider, __assign({ value: { stickyPlayer: stickyPlayer, setStickyPlayer: setStickyPlayer } }, { children: [_jsxs("div", { children: [_jsx(IconAndSymbolInlineEmbed, {}, void 0), _jsxs(Routes, { children: [_jsx(Route, { path: routes.episodeEmbedPlayer.path, element: _jsx(ProtectedRoute, { component: EpisodeEmbedPlayerRoute }, void 0) }, void 0), _jsx(Route, { path: routes.episodeDetailsPublic.path, element: _jsx(ProtectedRoute, { component: EpisodeDetailsPublicRoute }, void 0) }, void 0), _jsx(Route, { path: routes.showDetailsPublic.path, element: _jsx(ProtectedRoute, { component: ShowDetailsPublicRoute }, void 0) }, void 0), _jsx(Route, { path: '/show' + routes.episodeEmbedPlayer.path, element: _jsx(ProtectedRoute, { component: EpisodeEmbedPlayerRoute }, void 0) }, void 0), _jsx(Route, { path: '/show' + routes.episodeDetailsPublic.path, element: _jsx(ProtectedRoute, { component: EpisodeDetailsPublicRoute }, void 0) }, void 0), _jsx(Route, { path: '/show' + routes.showDetailsPublic.path, element: _jsx(ProtectedRoute, { component: ShowDetailsPublicRoute }, void 0) }, void 0)] }, void 0)] }, void 0), _jsx("div", { children: _jsx(StickyPlayer, {}, void 0) }, void 0)] }), void 0) }), void 0));
};
render(_jsx(BrowserRouter, __assign({ basename: ROUTE_BASE_NAME }, { children: _jsx(SkeletonTheme, __assign({}, SKELETON_THEME_PROPS, { children: _jsx(App, {}, void 0) }), void 0) }), void 0), document.getElementById('root'));
// @ts-ignore
if (import.meta.webpackHot) {
    // @ts-ignore
    import.meta.webpackHot.accept();
}
