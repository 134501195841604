var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
export var useLocalStorage = function (key, initialValue) {
    var _a = __read(useState(function () {
        var item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    }), 2), storedValue = _a[0], setStoredValue = _a[1];
    useEffect(function () {
        window.addEventListener('storage', onStorageUpdate);
        return function () {
            window.removeEventListener('storage', onStorageUpdate);
        };
    }, []);
    var onStorageUpdate = function (_a) {
        var changedKey = _a.key, newValue = _a.newValue;
        if (changedKey === key) {
            var nextValue = newValue ? JSON.parse(newValue) : initialValue;
            setStoredValue(nextValue);
        }
    };
    var setValue = function (value) {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
    };
    var removeValue = function (value) {
        window.localStorage.removeItem(key);
        setStoredValue(value);
    };
    return [storedValue, setValue, removeValue];
};
