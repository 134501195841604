import { DURATION_SCALE } from '../shared.constants';
export var formatDuration = function (duration) {
    var seconds = Math.floor((duration / 1000) % 60);
    var minutes = Math.floor((duration / (1000 * 60)) % 60);
    var hours = Math.floor(duration / (1000 * 60 * 60));
    // let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    var chunks = [];
    if (hours > 0) {
        chunks.push(hours + " hour");
    }
    if (minutes > 0) {
        chunks.push(minutes + " min");
    }
    if ((seconds > 0 || !chunks.length) && !hours) {
        chunks.push(seconds + " sec");
    }
    return chunks.join(' ');
};
var formatDate = function (date, delimeter) {
    if (delimeter === void 0) { delimeter = '-'; }
    var d = new Date(date);
    var month = (d.getMonth() + 1).toString();
    var day = d.getDate().toString();
    var year = d.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join(delimeter);
};
var formatDateToAMPM = function (d) {
    return d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).replace(':00', '');
};
export var formatUXDate = function (d, onlyDate) {
    if (onlyDate === void 0) { onlyDate = false; }
    var now = new Date();
    var result = '';
    var d_yyyymmdd = formatDate(d, '');
    var now_yyyymmdd = formatDate(now, '');
    var timeString = formatDateToAMPM(d);
    if (d_yyyymmdd.substring(0, 4) !== now_yyyymmdd.substring(0, 4)) {
        result += d.getFullYear();
        result += ' ';
    }
    if (d_yyyymmdd !== now_yyyymmdd) {
        result += d.toLocaleString('en-US', { month: 'short' });
        result += ' ';
    }
    if (d_yyyymmdd.substring(0, 8) !== now_yyyymmdd.substring(0, 8)) {
        result += d.getDate();
        result += ' ';
        // result += d.toLocaleString('en-US', { weekday: 'short' });
        // result += ' ';
    }
    if (d_yyyymmdd !== now_yyyymmdd) {
        if (result) {
            result = result.trim() + ', ';
        }
    }
    else {
        result += 'Today, ';
    }
    if (onlyDate) {
        result = result.substring(0, result.length - 2);
    }
    else {
        result += timeString;
    }
    return result.trim();
};
export var msToTime = function (duration) {
    var durationSec = duration / DURATION_SCALE;
    var durationInteger = parseInt(durationSec.toString(), 10);
    var hours = Math.floor(durationInteger / 3600);
    var minutes = Math.floor(durationInteger % 3600 / 60);
    var seconds = (durationInteger % 3600 % 60);
    var milliSeconds = parseInt(((durationSec - durationInteger) * 100).toString());
    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        milliSeconds: milliSeconds,
    };
};
export var toTwoDigits = function (value) { return Number(value) < 10 ? "0" + value : value.toString(); };
export var formatTime = function (duration, formatDigit) {
    if (formatDigit === void 0) { formatDigit = true; }
    var _a = msToTime(duration), hours = _a.hours, minutes = _a.minutes, seconds = _a.seconds, milliSeconds = _a.milliSeconds;
    var hoursString = hours.toString();
    var minutesString = minutes.toString();
    var secondsString = seconds.toString();
    var milliSecondsString = milliSeconds.toString();
    if (formatDigit) {
        minutesString = toTwoDigits(minutesString);
        secondsString = toTwoDigits(secondsString);
        milliSecondsString = toTwoDigits(milliSecondsString);
    }
    return {
        hours: hoursString,
        minutes: minutesString,
        seconds: secondsString,
        milliSeconds: milliSecondsString,
    };
};
