var _a;
export var BASE_URL = (_a = process.env) === null || _a === void 0 ? void 0 : _a.BASE_URL;
var getEnvMainHost = function () {
    var host = window.location.host.replace('shows-', '').replace('shows', '');
    if (host.startsWith('.')) {
        host = host.slice(1);
    }
    return host;
};
var constructAPIUrl = function () { return "https://" + getEnvMainHost() + "/api"; };
export var API_BASE_URL = constructAPIUrl();
export var refreshTokenUrl = API_BASE_URL + "/auth/token/refresh";
var constructEditorURL = function () { return "https://" + getEnvMainHost() + "/editor"; };
export var EDITOR_URL = constructEditorURL();
var constructLandingURL = function () { return "https://" + getEnvMainHost(); };
export var LANDING_URL = constructLandingURL();
var constructShowUrl = function () { return window.location.origin; };
export var SHOW_URL = constructShowUrl();
export var EDITOR_SIGNUP_URL = EDITOR_URL + "/signup";
export var EDITOR_PROFILE_SETTINGS_URL = EDITOR_URL + "/profile";
export var EDITOR_DIGITAL_VOICES_URL = EDITOR_URL + "/workspace/voices";
