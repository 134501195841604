export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["OK"] = "OK";
    HttpStatus["FORBIDDEN"] = "FORBIDDEN";
    HttpStatus["CONFLICT"] = "CONFLICT";
    HttpStatus["BAD_REQUEST"] = "BAD_REQUEST";
    HttpStatus["UNAUTHORIZED"] = "UNAUTHORIZED";
    HttpStatus["NOT_FOUND"] = "NOT_FOUND";
    HttpStatus["INTERNAL_SERVER_ERROR"] = "INTERNAL_SERVER_ERROR";
    HttpStatus["GATEWAY_TIMEOUT"] = "GATEWAY_TIMEOUT";
    HttpStatus["PERMANENT_REDIRECT"] = "PERMANENT_REDIRECT";
})(HttpStatus || (HttpStatus = {}));
export var IOErrorType;
(function (IOErrorType) {
    IOErrorType["NETWORK_ERROR"] = "NETWORK_ERROR";
    IOErrorType["HTTP_ERROR"] = "HTTP_ERROR";
})(IOErrorType || (IOErrorType = {}));
