var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classes } from 'react-scoped-styles';
import { isTouchDevice } from 'modules/shared/helpers/device';
import './progress.css';
export var Progress = function (_a) {
    var _b, _c, _d, _e;
    var _f = _a.value, value = _f === void 0 ? 0 : _f, _g = _a.rounded, rounded = _g === void 0 ? false : _g, _h = _a.transition, transition = _h === void 0 ? false : _h, _j = _a.progressColor, progressColor = _j === void 0 ? 'surface-neutral-200' : _j, _k = _a.indicatorColor, indicatorColor = _k === void 0 ? 'surface-secondary' : _k, _l = _a.readonly, readonly = _l === void 0 ? false : _l, _m = _a.height, height = _m === void 0 ? 4 : _m, containerRef = _a.containerRef, _o = _a.touchSpace, touchSpace = _o === void 0 ? 0 : _o, animated = _a.animated, _p = _a.handler, handler = _p === void 0 ? false : _p, _q = _a.hovered, hovered = _q === void 0 ? false : _q;
    var progressBarClassNames = classes('progress', (_b = {
            'rounded-full': rounded,
            'transition-all': transition,
            'pointer-events-none': readonly
        },
        _b['h-' + height] = !!height,
        _b['bg-' + progressColor] = !!progressColor,
        _b));
    var indicatorClassNames = classes('progress-indicator', (_c = {
            'rounded-l-full': rounded,
            'rounded-r-full': rounded && value >= 100
        },
        _c['h-' + height] = !!height,
        _c['bg-' + indicatorColor] = !!indicatorColor,
        _c['progress-animated'] = !!animated,
        _c));
    var wrapperClassNames = classes("", (_d = {},
        _d['py-' + touchSpace] = !!touchSpace,
        _d), 'flex-1');
    var handlerClassName = classes('absolute -ml-6 w-12 h-12 rounded-full transition-opacity', (_e = {},
        _e['bg-' + indicatorColor] = !!indicatorColor,
        _e['opacity-0'] = !isTouchDevice(),
        _e['opacity-100'] = hovered,
        _e));
    var handlerSize = 12;
    return (_jsx("div", __assign({ className: wrapperClassNames, ref: containerRef }, { children: _jsxs("div", __assign({ className: progressBarClassNames }, { children: [_jsx("div", { className: indicatorClassNames, style: { width: (value < 1 ? Math.ceil(value) : value) + "%" } }, void 0), handler ? (_jsx("div", { className: handlerClassName, style: {
                        top: "calc(" + height + "px / 2 - " + handlerSize / 2 + "px)",
                        left: (value < 1 ? Math.ceil(value) : value) + "%",
                    } }, void 0)) : null] }), void 0) }), void 0));
};
