import { buildUrl } from './helpers/navigation';
export var routes = {
    show: {
        path: '/',
        name: 'Show',
        getPath: function () { return '/'; },
    },
    episodeEmbedPlayer: {
        path: '/episode-embed-player/:showHash/:episodeHash',
        name: 'Episode Embed Player',
        getPath: function (_a) {
            var showHash = _a.showHash, episodeHash = _a.episodeHash;
            return buildUrl("/episode-embed-player/" + showHash + "/" + episodeHash);
        },
    },
    showDetailsPublic: {
        path: '/:showLink',
        name: 'Show details',
        getPath: function (path) { return path; },
    },
    episodeDetailsPublic: {
        path: '/:showLink/:episodeLink',
        name: 'Episode details',
        getPath: function (path) { return path; },
    },
};
